import React from "react"
import { makeStyles } from "@material-ui/styles"
import { injectIntl, FormattedMessage } from "react-intl"
import { Button } from ".."
import backgroundImg from "../../puraearth/src/images/puraearth-symbol-background-green.svg"
import { Link } from "gatsby"

const styles = makeStyles(theme => {
  return {
    container: {
      backgroundColor: theme.palette.green.support,
      height: theme.spacing(51),
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      flexDirection: "column",
      backgroundImage: `url(${backgroundImg})`,
      backgroundPosition: "100px 60px",
      backgroundRepeat: "no-repeat",
      [theme.breakpoints.down("md")]: {
        backgroundImage: "none",
      },
    },
    title: {
      color: theme.palette.green.main,
      fontFamily: theme.typography.fontFamily,
      fontSize: "44px",
      fontWeight: "300",
      textAlign: "center",
      marginTop: theme.spacing(0),
      [theme.breakpoints.down("md")]: {
        fontSize: "28px",
      },
    },
    text: {
      color: theme.palette.tertiary.main,
      fontFamily: theme.typography.fontFamily,
      fontSize: "20px",
      lineHeight: "28px",
      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
        marginLeft: theme.spacing(2.5),
        marginRight: theme.spacing(2.5),
        fontSize: "16px",
      },
    },
    buttonContainer: {
      marginTop: theme.spacing(5),
      "& a": {
        textDecoration: "none",
      },
      "& a:hover": {
        textDecoration: "none",
      }
    },
  }
})

export default injectIntl(props => {
  const classes = styles(props)
  const homepagePath = "/" + props.locale;

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>{props.title}</h1>
      <span className={classes.text}>{props.text}</span>
      <div className={classes.buttonContainer}>
        <Link to={homepagePath} >
          <Button color="green">
            <FormattedMessage id='backHomepage' />
          </Button>
        </Link>
      </div>
    </div>
  )
})
